import React from 'react';
import styled from 'styled-components';

const Loading = ({ position = 'fixed' }) => (
  <Wrapper position={position}>
    <LdsRing className="lds-ring">
      <div />
      <div />
      <div />
      <div />
    </LdsRing>
  </Wrapper>
);

export const FullPageLoading = () => (
  <FullPageWrapper>
    <Loading />
  </FullPageWrapper>
);

const FullPageWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
`;

const Wrapper = styled.div`
  position: ${({ position }) => position};
  background: rgba(255, 255, 255, 0);
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LdsRing = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 44px;
    height: 44px;
    border: 5px solid ${({ theme }) => theme.primaryColor};
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${({ theme }) => theme.primaryColor} transparent transparent transparent;
  }

  div:nth-child(1) {
    animation-delay: -0.45s;
  }

  div:nth-child(2) {
    animation-delay: -0.3s;
  }

  div:nth-child(3) {
    animation-delay: -0.15s;
  }

  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export default Loading;
