import React from 'react';
import { withConfig } from '@catalogo/core-connect/config';

const fmtOrganizationSchema = ({ config }) => {
  const { shortName, domainUrl, email, socialMedia, ogImage } = config;

  return {
    '@type': 'Organization',
    address: {
      '@type': 'PostalAddress',
      addressLocality: 'Rio de Janeiro, Brazil',
      postalCode: '20081-902',
      streetAddress: 'Rua Sacadura Cabral, 130',
    },
    email,
    id: '#organization',
    image: {
      '@id': '#logo',
    },
    legalName: 'B2W - Companhia Digital',
    logo: {
      '@id': '#logo',
      '@type': 'ImageObject',
      caption: shortName,
      url: ogImage,
    },
    name: shortName,
    sameAs: socialMedia,
    url: domainUrl,
  };
};

const fmtWebSiteSchema = ({ config }) => {
  const { domainUrl, shortName } = config;

  return {
    '@id': '#website',
    '@type': 'WebSite',
    name: shortName,
    potentialAction: {
      '@type': 'SearchAction',
      'query-input': 'required name=search_term_string',
      target: `${domainUrl}/busca?conteudo={search_term_string}`,
    },
    publisher: {
      '@id': '#organization',
    },
    url: domainUrl,
  };
};

const fmtWebPageSchema = ({ config, page }) => {
  const { domainUrl, ogImage } = config;
  const { description, title, canonical } = page ? page : {};
  const id = canonical !== '/' ? canonical : '';

  return {
    '@id': `${id}#webpage`,
    '@type': 'WebPage',
    description: description,
    image: {
      '@id': `${id}#primaryimage`,
    },
    inLanguage: 'pt-BR',
    isPartOf: {
      '@id': '#website',
    },
    mainEntityOfPage: {
      '@id': '#website',
    },
    name: title,
    primaryImageOfPage: {
      '@id': `${id}#primaryimage`,
      '@type': 'ImageObject',
      url: ogImage,
    },
    url: domainUrl,
  };
};

export const Schema = props => {
  const graph = [fmtOrganizationSchema(props), fmtWebSiteSchema(props), fmtWebPageSchema(props)];

  const schema = {
    '@context': 'https://schema.org',
    '@graph': graph,
    '@type': 'Schema',
  };

  return <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }} />;
};

export default withConfig(Schema);
