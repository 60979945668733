import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper } from '@catalogo/theme-wrapper';
import BlackboxArea from '@catalogo/core-blackbox/area';
import { FullPageLoading } from '@catalogo/ui-americanas-mobile-loading';
import Error from '@catalogo/template-americanas-mobile-error';
import { withPageHome } from '@catalogo/service-americanas-home/src/hocs/home';
import Schema from './schema';

export const HomeTemplate = props => {
  const { loading, error, refetch, publications, routeId, data } = props;

  if (loading) {
    return <FullPageLoading />;
  }

  if (error) {
    return <Error retry={() => refetch()} />;
  }

  return (
    <Wrapper>
      {data && <Schema {...props} {...data} />}
      <BlackboxArea area="top" routeId={routeId} publications={publications} />
      <BlackboxArea area="middle" routeId={routeId} publications={publications} />
      <BlackboxArea area="bottom" routeId={routeId} publications={publications} />
    </Wrapper>
  );
};

HomeTemplate.propTypes = {
  routeId: PropTypes.string,
  location: PropTypes.object,
  template: PropTypes.object,
  parsedQs: PropTypes.object,
};

export default withPageHome(HomeTemplate);
